<template>
  <b-navbar id="menu-mobile" toggleable="lg" type="dark" variant="menu-primary" class="text-center" sticky>
    <b-navbar-brand class="md-mx-auto">      
      <a href="/#/home">
        <img class="navbar-logo" :src="$api.ste.logo">
      </a>      
    </b-navbar-brand>
    <b-button v-b-toggle.nav-collapse variant="menu-primary" class="navbar-toggle btn-icon">
      <i class="fas fa-bars"></i>
    </b-button>
    <b-collapse id="nav-collapse" class="mt-3" is-nav>
      <b-navbar-nav class="ml-3">
        <router-link v-for="(m,i) in menu" :key="i" :to="m.path" v-slot="{ navigate, href, route, isActive }">
          <b-nav-item  class="menu-desktop-element" :href="href" :active="isActive">
            <i :class="m.icon" class="text-white"></i>
            <span class="ml-3 text-white">{{ m.titre }}</span>            
          </b-nav-item>
        </router-link>
      </b-navbar-nav>
      <b-navbar-nav class="text-center mt-3">
        <div class="pr-1">
          <b-button variant="danger" class="btn-icon" size="sm">
            <i class="fas fa-sign-out-alt"></i>
          </b-button>
          <b-button variant="light-primary text-white" class="ml-3" size="sm">
            {{initial}}
          </b-button>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  name: "",
  props: ['menu'],
  data(){
    return {
    }
  },
  computed:{
    initial(){
      var tmp = this.$api.user.prenom[0]+this.$api.user.nom[0];
      return tmp.toUpperCase()
    }
  },
  components: {
  }
}
</script>
<style scoped>
</style>
