import { io } from 'socket.io-client';
import Api from './Api';
class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection() {    
    this.socket = io( Api.url.socket,{
        auth: Api.user        
    });
    return this.socket;
  }
  disconnect() {
    if (this.socket) {
        this.socket.disconnect();
    }
  }
}

export default new SocketioService();