<template>
  <div id="header" class="sticky-top bg-light-white">    
    <div class="p-5 d-flex">
      <div class="">
        <router-link v-for="(l,i) in last" :key="i"  :to="l.path" v-slot="{ navigate, href, route, isActive }">
          <b-button :href="href" :active="isActive" size="sm" variant="light-info" :class="i > 0 ? 'ml-2' : ''">
            {{ l.name }}
          </b-button>
        </router-link>        
      </div>
      <div class="ml-auto">                
        <b-dropdown v-if="pbx_prefix != false" size="sm" variant="light-success" no-caret v-b-tooltip.hover title="Prefix d'appel 3CX" style="z-index:60 !important">
          <template #button-content>
            <i class="fa fa-phone"></i> {{  pbx_prefix.code }}
          </template>
          <b-dropdown-item href="javascript:void(0)" v-for="(p, i) in $api.user.pbx_prefix" :key="i" :active="pbx_prefix.code === p.code" @click="pbx_prefix = p">{{ p.numero }} ({{ p.code }})</b-dropdown-item>          
        </b-dropdown>                
        <b-button size="sm" variant="light-warning" class="text-white ml-2" :class="nbTodo > 0 ? '' : 'btn-icon'" v-b-toggle.to_do v-b-tooltip.hover title="Vos taches">
          <i class="fa fa-list"></i><span class="badge badge-danger ml-2" v-if="nbTodo > 0">{{ nbTodo }}</span>
        </b-button>
        <b-button size="sm" variant="light-primary" class="text-white  ml-2" v-b-tooltip.hover :title="`Bonjour ${$api.user.prenom} ${$api.user.nom}`">
          {{ initial }}
        </b-button>
        <b-button size="sm" class="btn-icon ml-2" variant="light-danger" v-b-tooltip.hover title="Se déconnecter" @click="$api.destroyToken()">
          <i class="fas fa-sign-out-alt"></i>
        </b-button>
      </div>
    </div>
    <b-sidebar v-model="todoSide" :class="todoSide ? 'todo-open' :''" id="to_do" title="Liste des taches" width="500px" backdrop-variant="light-warning" backdrop shadow>
      <div class="px-3 py-2" v-if="todoTmp != false">
        <div class="d-block mt-5 mb-5 text-center">
          <b-button variant="light-danger" @click="resetTodo()">Supprimer les taches terminées</b-button>
        </div>
        <b-list-group>          
          <b-list-group-item href="javascript:void(0)">
            <b-input-group>
              <b-form-textarea  v-model="todoTmp.titre" placeholder="Ajouter votre tache" rows="1"></b-form-textarea>
              <!-- <b-form-input placeholder="Ajouter votre tache" v-model="todoTmp.titre" v-on:keyup.enter="saveTodo()"/> -->
              <b-input-group-append>
                <b-button @click="saveTodo()" variant="success" class="btn-icon"><i class="fa fa-plus text-white"></i></b-button>            
              </b-input-group-append>
            </b-input-group>
          </b-list-group-item>          
          <b-list-group-item href="javascript:void(0)" v-for="(d, i) in todo" :key="i">
            <div>
              <b-row>
                <b-col sm="2" class="my-auto">
                  <b-form-checkbox class="d-inline" size="lg" v-model="d.status_id" :value="2" :unchecked-value="1" :disabled="d.status_id === 2" @change="saveTodo(d)"></b-form-checkbox>
                </b-col>
                <b-col sm="9" class="my-auto">
                  <p class="h6 align-middle" style="line-break:anywhere;" :class="d.status_id === 2 ? 'tache-fini' : ''">{{ d.titre }}</p>
                </b-col>
                <b-col sm="1" class="my-auto">
                  <b-button variant="danger" class="btn-icon align-middle" size="xs" @click="d.status_id = 3; saveTodo(d);"><i class="fa fa-trash"></i></b-button>
                </b-col>
              </b-row>                            
            </div>            
          </b-list-group-item>          
        </b-list-group>        
      </div>
    </b-sidebar>    
  </div>
</template>
<script>
export default {
  name: "Header",  
  components: {    
  },
  data(){
    return {
      socket: false,
      pbx_prefix: false,
      todoSide:false,      
      last:[],
      todoTmp:false,
      todo:[],
      nbTodo:0,      
    }
  },  
  created() {    
  },
  beforeUnmount() {    
  },
  watch: {    
    last(){
      if(this.last.length > 5){
        this.last = this.last.slice(0,5);
      }
    }, 
    'pbx_prefix.code'(){
      this.$api.pbx_prefix = this.pbx_prefix;
    }
  },  
  
  computed:{
    initial(){
      var tmp = this.$api.user.prenom[0]+this.$api.user.nom[0];
      return tmp.toUpperCase()
    },    
  },
  methods:{
    initTodo(){
      this.$api.ajax('/todo/liste', null, res => {
        if(res.status === true){
          this.todo = res.data;
          this.nbTodo = this.todo.filter(t => t.status_id === 1).length;
        }
      });
    },    
    saveTodo(todo = false){
      var params = !todo ? this.todoTmp : todo
      this.$api.ajax('/todo/create', params, res => {
        if(res.status === true){
          this.todoTmp.titre = null;
          this.initTodo();
        }
      });
    },
    resetTodo(){
      this.$api.ajax('/todo/reset', null, res => {
        if(res.status === true){
          this.initTodo();
        }
      })
    },        
  },
  beforeMount(){
    this.$api.ajax('/todo/new', null, res => {
      if(res.status === true){
        this.todoTmp = res.data;
      }
    });
  },
  mounted(){
    this.initTodo();
    var check = this.$api.user.pbx_prefix.find(p => p.defaut === true);
    if(check != undefined){
      this.pbx_prefix = check;
    }    
  }
}
</script>
<style scodped>
.tache-fini {
  text-decoration:line-through;
}
.align-middle {
  vertical-align: middle;
}
.btn-xs {
  border-radius: 6px;
}
.notif-back{

}
</style>
