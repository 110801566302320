<template>
    <div class="sticky-top">
        <!-- <b-img class="logo" src="https://jg-connect.com/img/logo.44bb340d.png"></b-img> -->
        <b-img class="logo" :src="$api.ste.logo"></b-img>
        <ul class="list-unstyled text-left">
            <router-link v-for="(m,i) in menu" :key="i" :to="m.path" v-slot="{ navigate, href, route, isActive }">
                <a :href="href">
                    <b-media tag="li" :class="isActive ? 'active' : ''">
                        <div class="p-3 ml-5">
                            <i :class="m.icon"></i>
                            <span class="h4 text-bolder">{{ m.titre }}</span>
                        </div>
                    </b-media>
                </a>
            </router-link>                        
        </ul>
        <div class="py-2 px-5">
            <hr style="border-color:red"/>
            <div v-for="(n, i) in notifs" :key="i" class="bg-white rounded mb-2" @click="openNotif(n)" style="cursor:pointer;">
                <span class="">{{ n.params.titre }}</span>                                            
            </div>            
        </div>        
    </div>
</template>
<script>
    import SocketioService from '@/core/socketio.service.js';
    export default {
        name: "MenuDesktop",
        props:['menu'],
        data() {
            return {                
                notifs:[],            
            }
        },
        computed:{              
        },
        created() {
            this.socket = SocketioService.setupSocketConnection();
            this.socket.on('connect', () => {
                this.socket.on('notif', () => {                                           
                    this.initNotif();
                });
            });
        },
        beforeUnmount() {
            SocketioService.disconnect();
        },
        methods:{    
            initNotif(){
                var params = {
                    "sort":{
                        "key":"id",
                        "value":"DESC"
                    },
                    "page":1,
                    "limit":null,
                    "filters":{
                        "is_open":false,
                        "params.titre":"Nouveau lead - ref"
                    }
                };
                this.$api.ajax('/notification/liste', params, res => {
                    this.notifs = res.data;                
                });
            }, 
            openNotif(n){
                this.$api.ajax('/notification/open/'+n.id, null, res => {
                    if(res.status === true){          
                        window.open(n.params.link, '_blank');                        
                        this.initNotif();
                    }
                })
            },       
        },
        mounted(){
            var fillout = 'https://server.fillout.com/embed/v1/';
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', fillout)
            document.head.appendChild(recaptchaScript);     
            this.initNotif();           
        }
    }
</script>
<style>

</style>

